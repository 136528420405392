import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import messageContentString from '@/lib/messageContentString';
import byPairsQueryOptions from '@/lib/queries/byPairs';
import dialogQueryOptions from '@/lib/queries/dialog';
import { girlsProfilesQueryOptions } from '@/lib/queries/girlsProfiles';
import profileQueryOptions from '@/lib/queries/profile';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import { Cross2Icon } from '@radix-ui/react-icons';

export default function MessageToast({
  idUser,
  idInterlocutor,
  onClose,
}: {
  idUser: number;
  idInterlocutor: number;
  onClose: () => void;
}) {
  const qc = useQueryClient();

  const profileData = useQuery(profileQueryOptions(qc, idInterlocutor)).data;

  const dialogData = useQuery(
    dialogQueryOptions(qc, idUser, idInterlocutor)
  ).data;

  useQuery({
    ...byPairsQueryOptions(qc, idInterlocutor, idUser),
    enabled: !(dialogData && profileData),
  });

  const girlProfile = useQuery(girlsProfilesQueryOptions).data?.find(
    profile => profile.id === idUser
  );

  if (!profileData || !girlProfile || !dialogData) return <div>Loading</div>;

  return (
    <div className='relative flex items-center py-2'>
      <Link
        to='/chat/$dialogId'
        params={{ dialogId: `${idUser}_${idInterlocutor}` }}
      >
        <div className='flex items-center gap-2 text-base font-semibold'>
          <div>
            <Avatar>
              <AvatarImage src={profileData.personal.avatar_large} />
              <AvatarFallback>{profileData.name}</AvatarFallback>
            </Avatar>
          </div>
          <div>
            <span className='w-20 truncate text-start'>
              {profileData.name}
              {', '}
              {profileData.personal.age}
            </span>
            <div className='w-20 truncate text-sm font-normal text-muted-foreground'>
              {messageContentString(dialogData)}
            </div>
          </div>
          <div className='flex items-center gap-2'>
            <span className='w-20 truncate text-end'>{girlProfile.name}</span>
            <Avatar>
              <AvatarImage src={girlProfile.personal.avatar_large} />
              <AvatarFallback>{girlProfile.name}</AvatarFallback>
            </Avatar>
          </div>
        </div>
      </Link>
      <button onClick={onClose} className='absolute -right-4 -top-1.5 p-0.5'>
        <Cross2Icon className='size-4 text-foreground' />
      </button>
    </div>
  );
}
